import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import LoadingScreen from './globalComponent/Loader/LoadingScreen';
import LoginPage from './pages/LoginPage';
import SignUpPage from './pages/SignUpPage';
import { MobileProvider } from './globalComponent/context/MobileContext';
import DriverHomePage from './pages/DriverHomePage';
import SignUpCarDetails from './components/signUp/SignUpCarDetails';
import { GlobalStateProvider } from './utils/GlobalState';
import DriverEarnings from './components/driverPages/DriverEarnings';
import DriverProfile from './components/driverPages/DriverProfile';
import TripHistory from './components/driverPages/TripHistory';
import NavigationBar from './globalComponent/ui/NavigationBar';
import { LoaderProvider } from './globalComponent/context/LoaderContext';
import './App.css';
import Alert from './globalComponent/ui/Alert';
import { UserProvider } from './globalComponent/context/AppContext';

function App() {
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const startTime = Date.now();

    // Simulate page content loading (replace with actual loading logic)
    setTimeout(() => {
      const endTime = Date.now();
      const elapsedTime = endTime - startTime;

      // Adjust the minimum loading time if the page content takes longer than 500ms
      const minLoadingTime = Math.max(500, elapsedTime);

      const timer = setTimeout(() => {
        setIsLoading(false);
      }, minLoadingTime);

      return () => clearTimeout(timer);
    }, 500); // Simulated page content loading time
  }, []);

  return (
    <div className="App">
      <GlobalStateProvider>
        <UserProvider>
        <LoaderProvider>
          <MobileProvider>
          {isLoading ? (
            <LoadingScreen />
          ) : (
            <Router>
              <div className='relative w-full h-full'>
                <Alert />

                <Routes>
                  <Route path='/' element={<Navigate to="/login" />} />
                  {/* <Route path='/tutorial' element={<TutorialPage />} /> */}
                  <Route path='/login' element={<LoginPage />} />
                  <Route path='/signup' element={<SignUpPage />} />
                  <Route path='/signup/car' element={<SignUpCarDetails />} />
                  {/* <Route path='/setup' element={<SetupPage />} /> */}
                  <Route path='/driver/*' element={<DriverHomePage />} />
                  <Route path='/earnings' element={<DriverEarnings />} />
                  <Route path='/profile' element={<DriverProfile />} />
                  <Route path='/trip-history' element={<TripHistory />} />
                  {/* <Route path='/courier/*' element={<CourierPage />} /> */}
                  {/* <Route path='/verification/*' element={<VerificationPage />} /> */}
                </Routes>
                <NavigationBar />
              </div>
            </Router>
          )}
          </MobileProvider>
        </LoaderProvider>
        </UserProvider>
      </GlobalStateProvider>
    </div>
  );
}

export default App;
