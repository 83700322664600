import React, { useEffect, useState } from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa6';
import EarningBarChart from "./EarningBarChart"
import axiosInstance from '../../utils/axiosInstance';
import '../../styles/component/driver/DriverEarnings.css';
import RechargePopUp from './components/RechargePopUp';

const DriverEarnings = () => {
  const [earningsData, setEarningsData] = useState([]);
  const [totalEarnings, setTotalEarnings] = useState(0);
  const [displayedEarnings, setDisplayedEarnings] = useState([]);
  const [startIndex, setStartIndex] = useState(0);
  const [ showRechargePopUp, setShowRechargePopUp ] = useState(false)
  const [amount, setAmount] = useState(0)
  const driverId = sessionStorage.getItem('driverId');
  const userId = sessionStorage.getItem("userId")

  useEffect(() => {
    if (userId) {
      axiosInstance
      .get(`wallet/amountcheck/${userId}/`)
      .then(res => {
        if (res.data.Wallet) {
          setTotalEarnings(res.data.Wallet.balance)
        } else if (res.data.Amount) {
          setTotalEarnings(res.data.Amount.balance)
        }
        // setShowBalance(true)
        // setBalanceId(id)
        console.log(res)
      })
      .catch(err => {
        console.log(err)
      })
    }
  }, [userId, startIndex]);

  const handlePrev = () => {
    if (startIndex > 0) {
      setStartIndex(startIndex - 4);
    }
  };

  const handleNext = () => {
    if (startIndex + 4 < earningsData.length) {
      setStartIndex(startIndex + 4);
    }
  };

  return (
    <div className='w-full relative'>
      <div className='absolute top-0 left-0 tooki-gradient w-full h-80 -z-10'>
      </div>

      {showRechargePopUp &&
      <div className='w-screen fixed z-50 h-screen bg-black bg-opacity-40 top-0 left-0'>
        <RechargePopUp 
          userId={userId}
          amount={amount}
          setAmount={setAmount}
          setRechargePopUp={setShowRechargePopUp}
          // handlePayment={handlePayment}
        />
      </div>}

      <div className='w-full pt-10 flex flex-col items-center justify-start p-5'>

        <p className='text-2xl font-semibold '>My Earnings</p>

        <div className='w-full max-w-[50rem] bg-white mt-10 rounded-2xl shadow-lg flex items-center justify-between p-2 py-4'>
          <div className='text-left'>
            <p className='text-black text-opacity-50 font-semibold'>Wallet Balance</p>
            <p className='text-black font-bold text-xl'>Rs. {totalEarnings}</p>
          </div>
          <div className='flex flex-col w-32 h-full'>
            <button onClick={() => setShowRechargePopUp(true)} className='w-full cursor-pointer mb-2 rounded-lg h-10 bg-green-700 bg-opacity-90 flex items-center justify-center text-white'>
              Recharge
            </button>
          </div>
        </div>

        <div className='earnings-data-card  max-w-[50rem] mt-10 z-10 bg-white'>
          <div className='earnings-data-row bold-data-row'>
            <p className='earnings-label'>Earnings</p>
            <p className='earnings-num'>Rs. {totalEarnings}</p>
          </div>
          <div className='earnings-data-row'>
            <p className='earnings-label'>Trip Earnings</p>
            <p className='earnings-num'>Rs. {totalEarnings}</p>
          </div>
          <div className='earnings-data-row'>
            <p className='earnings-label'>Taxes</p>
            <p className='earnings-num'>Rs. 0</p>
          </div>
        </div>
      </div>

      <div className='w-full p-5 flex items-center justify-center'>
        <div className='earnings-right w-full max-w-[50rem] bg-white'>
          <div className='earnings-graph-card'>
            <p className='earnings-time-period'>{displayedEarnings[displayedEarnings?.length - 1]?.order_date} to {displayedEarnings[0]?.order_date}</p>

            <div className='daily-earnings-div'>
              <p className='calender-btns' onClick={handlePrev}><FaChevronLeft /></p>
              <p className='earning-text'>Rs. {displayedEarnings.reduce((acc, item) => acc + item.total_amount, 0)}</p>
              <p className='calender-btns' onClick={handleNext}><FaChevronRight /></p>
            </div>

            <div className='bar-chart-container'>
              <EarningBarChart data={displayedEarnings} />
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default DriverEarnings;
