import React, { createContext, useContext } from 'react';
import { useJsApiLoader } from '@react-google-maps/api';

const LoaderContext = createContext(null);

const GOOGLE_MAPS_LIBRARIES = ['places'];

export const LoaderProvider = ({ children }) => {
    const loaderOptions = {
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
        libraries: GOOGLE_MAPS_LIBRARIES
    }

  const loader = useJsApiLoader(loaderOptions);

  return (
    <LoaderContext.Provider value={loader}>
      {children}
    </LoaderContext.Provider>
  );
};

export const useLoader = () => useContext(LoaderContext);