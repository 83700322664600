import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom"
import { useForm } from 'react-hook-form';
import { IoIosMail, IoIosLock, IoMdEye, IoMdEyeOff } from "react-icons/io";
import loginImage from "../../assets/login/login.png"
import Background from '../Tutorial/component/Background'
import "../../styles/component/login/Login.css"
import axiosInstance from '../../utils/axiosInstance';
import { GlobalStateContext } from '../../utils/GlobalState';
import { ThreeDots } from 'react-loader-spinner';
import ForgotPassword from './ForgotPassword';

const Login = ({ setOtpLogin }) => {
    const navigate = useNavigate()
    const { updateState } = useContext(GlobalStateContext);
    const { register, handleSubmit, formState: { errors } } = useForm()
    const [showPassword, setShowPassword] = useState(false)
    const [ loader, setLoader ] = useState(false)
    const [ errorMessage, setErrorMessage ] = useState()
    const [ forgotPass, setForgotPass ] = useState(false)

    useEffect(() => {
        localStorage.clear()
        sessionStorage.clear()
    }, [])

    useEffect(() => {
        if (errorMessage) {
          const timer = setTimeout(() => {
            setErrorMessage(null);
          }, 3000); // Set to 3 seconds
      
          // Clear the timeout if the component unmounts or errorMessage changes
          return () => clearTimeout(timer);
        }
      }, [errorMessage]);

    const onSubmit = (data) => {
        setLoader(true)
        const loginData = {
            email: data.email,
            password: data.password,
            login_type: "driver"
        }
        axiosInstance
            .post("/login", loginData)
            .then(res => {
                const response = res.data.data
                localStorage.setItem("access", response.access)
                localStorage.setItem("refresh", response.refresh)
                sessionStorage.setItem("driverId", response.user.driver_additional_details.driver)
                sessionStorage.setItem("logged", true)
                sessionStorage.setItem("userId", response.user.id)

                updateState({ 
                    name: response.user.first_name + " " + response.user.last_name, 
                    userId: response.user.id 
                });

                navigate("/driver")
            })
            .catch(err => {
                console.log(err)
                setErrorMessage(err.response.data.message)
            })
            .finally(() => {
                setLoader(false)
            })
    };

    const goToSignUp = () => {
        navigate("/signup")
    }

  return (
    <Background image={loginImage}>
       <div className='login-main-div'>
            <p className='main-title supermercado !mb-0'>
                Sign In
            </p>

            {forgotPass && 
            <ForgotPassword
                setForgotPass={setForgotPass}
            />}

            {errorMessage &&
            <p className='pb-3 text-red-500 font-light glow-red'>
                {errorMessage}
            </p>}

            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group">
                    <div className='icon-wrap'>     
                        <IoIosMail />
                    </div>
                    <input
                        type="email"
                        placeholder='Email'
                        {...register("email", { required: true })}
                        className={errors.email? 'form-control input-error' : "form-control"}
                    />
                </div>

                <div className="form-group">
                    <div className='icon-wrap'>
                        <IoIosLock />
                    </div>
                    <input
                        type={showPassword ? 'text' : 'password'}
                        placeholder='Password'
                        {...register("password", { required: true })}
                        className={errors.email? 'form-control input-error' : "form-control"}
                    />
                    <button
                        type="button"
                        className="show-hide-btn"
                        onClick={() => setShowPassword(!showPassword)}
                    >
                        {showPassword ? <IoMdEyeOff /> : <IoMdEye /> }
                    </button>
                </div>

                <div className='w-full flex items-center justify-between -mt-3 mb-2'>
                    <div className='checkbox-div'>
                        <p onClick={() => setOtpLogin(true)} className='underline text-orange-600 text-xs font-medium'>
                            sign in with otp
                        </p>
                    </div>
                    <button 
                        onClick={() => setForgotPass(true)}
                        className='text-sm text-gray-600 text-opacity-70 hover:text-green-600'
                    >
                        Forgot Password?
                    </button>
                </div>

                <button type="submit" className="main-login-btn supermercado">
                    {!loader? "Login" : <ThreeDots color='#ffffff' />}
                </button>
            </form>

            <button onClick={goToSignUp} className='create-new-btn'>
                Create a new account
            </button>
       </div>
    </Background>
  )
}

export default Login