import React, { useContext, useEffect, useState } from 'react'
import profilePlaceholder from "../../assets/placeholder/profile-placeholder.png"
import { FaStar } from "react-icons/fa6";
import { FaRegStar } from "react-icons/fa";
import axiosInstance from '../../utils/axiosInstance';
import PreviousTripCard from '../../globalComponent/ui/PreviousTripCard';
import "../../styles/component/driver/TripHistory.css"
import { GlobalStateContext } from '../../utils/GlobalState';

const TripHistory = () => {
    const { state } = useContext(GlobalStateContext)
    const rating = 4.8
    const ratingsIndex = [ 1, 2, 3, 4, 5 ]

    const [ tripMode, setTripMode ] = useState(1)
    const [ prevTrips, setPrevTrips ] = useState()

    useEffect(() => {
        if (state.userId) {
            axiosInstance
                .get(`/logistics/driver/orders/${state.userId}`)
                .then(res => {
                    const response = res.data.data;
                    const delivered = response.filter(item => item.status === "DELIVERED")
                    setPrevTrips(delivered)
                })
                .catch(err => {
                    console.log(err)
                })
        }
    }, [state])

  return (
    <div className='w-full relative'>
        <div className='absolute top-0 left-0 tooki-gradient w-full h-80 -z-10'>
        </div>
        
        <div className='w-full pt-10 flex flex-col items-center justify-start p-5'>
        <p className='text-2xl font-semibold '>
            Trip History
        </p>

        <div className='w-full max-w-[50rem] bg-white mt-10 rounded-2xl shadow-lg flex items-center gap-10 h-28 p-5 py-4'>
            <img
                src={profilePlaceholder}
                className='driver-profile-image'
                alt='driver'
            />

            <div className=''>
                <p className='text-xl text-left'>
                    {state.name}
                </p>
                <p className='flex text-orange-500 items-center gap-2'>
                    {ratingsIndex.map(value => (
                        (value > rating)? <FaRegStar /> : <FaStar />
                    ))}

                    <span className='flex items-center'>
                        {" " + rating}
                    </span>
                </p>
            </div>
        </div>

        <div className='trips-info-div mt-10'>
            <div className='w-full flex items-center justify-between gap-20 px-8'>
                <button 
                    className="text-xl"
                    style={{ color: (tripMode ===1)? "white" : "black"}}
                    onClick={() => setTripMode(1)}
                >
                    Completed
                </button>
                <button 
                    className='text-xl'
                    style={{ color: (tripMode ===2)? "white" : "black"}}
                    onClick={() => setTripMode(2)}
                >
                    Upcoming
                </button>
            </div>

            <div className='z-10 w-full mt-10'>
                {prevTrips?.map((item, id) => (
                    <PreviousTripCard earning={item.order} index={id} />
                ))}
            </div>
        </div>
        </div>
    </div>
  )
}

export default TripHistory