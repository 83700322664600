import React, { useState, useContext, useEffect } from 'react'
import { useNavigate } from "react-router-dom"
import { useForm } from 'react-hook-form';
import { IoIosMail, IoIosLock, IoMdEye, IoMdEyeOff } from "react-icons/io";
import Navigator from '../../globalComponent/navigator/Navigator';
import axiosInstance from '../../utils/axiosInstance';
import { GlobalStateContext } from '../../utils/GlobalState';
import loginImage from "../../assets/login/login.png"
import "../../styles/component/login/Login.css"
import { ThreeDots } from 'react-loader-spinner';
import ForgotPassword from './ForgotPassword';

const Loader = () => {
    return (
        <ThreeDots
            visible={true}
            height="80"
            width="80"
            color="#FFF"
            radius="9"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClass=""
        />
    )
}

const LoginPage = ({ setOtpLogin }) => {
    const navigate = useNavigate()
    const { updateState } = useContext(GlobalStateContext);
    const { register, handleSubmit, formState: { errors } } = useForm()
    const [ showPassword, setShowPassword] = useState(false)
    const [ loader, setLoader ] = useState(false)
    const [ errorMessage, setErrorMessage ] = useState()
    const [ forgotPass, setForgotPass ] = useState(false)

    useEffect(() => {
        localStorage.clear()
        sessionStorage.clear()
    }, [])

    useEffect(() => {
        if (errorMessage) {
          const timer = setTimeout(() => {
            setErrorMessage(null);
          }, 3000); // Set to 3 seconds
      
          // Clear the timeout if the component unmounts or errorMessage changes
          return () => clearTimeout(timer);
        }
      }, [errorMessage]);

    const onSubmit = (data) => {
        setLoader(true)
        const loginData = {
            email: data.email,
            password: data.password,
            login_type: "driver"
        }
        axiosInstance
            .post("/login", loginData)
            .then(res => {
                const response = res.data.data
                localStorage.setItem("access", response.access)
                localStorage.setItem("refresh", response.refresh)
                sessionStorage.setItem("driverId", response.user.driver_additional_details.driver)
                sessionStorage.setItem("logged", true)
                sessionStorage.setItem("userId", response.user.id)

                updateState({ 
                    name: response.user.first_name + " " + response.user.last_name, 
                    userId: response.user.id 
                });

                navigate("/driver/")
            })
            .catch(err => {
                console.log(err)
                setErrorMessage(err.response.data.message)
            })
            .finally(() => {
                setLoader(false)
            })
    };

    const goToSignUp = () => {
        navigate("/signup")
    }

  return (
    <div className='login-main-div'>

        <Navigator pageTitle={"Sign In"} />

        {forgotPass && 
        <ForgotPassword
            setForgotPass={setForgotPass}
        />}

        
        <div className='top-image-container'>
            <img src={loginImage} className='login-hero-image' alt='hero' />
        </div>

        <div className='bottom-form-container'>
            {errorMessage &&
            <p className='pb-3 text-red-500 font-light glow-red'>
                {errorMessage}
            </p>}
            
            {/* <p className='main-title'>
                Sign In
            </p> */}
            <p className='welcome-text'>
                Welcome!
            </p>
            <p className='form-title'>
                Sign in to continue
            </p>

            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group">
                    <div className='icon-wrap'>     
                        <IoIosMail />
                    </div>
                    <input
                        type="email"
                        placeholder='Email'
                        disabled={loader}
                        {...register("email", { required: true })}
                        className={errors.email? 'form-control input-error' : "form-control"}
                    />
                </div>

                <div className="form-group">
                    <div className='icon-wrap'>
                        <IoIosLock />
                    </div>
                    <input
                        type={showPassword ? 'text' : 'password'}
                        placeholder='Password'
                        disabled={loader}
                        {...register("password", { required: true })}
                        className={errors.email? 'form-control input-error' : "form-control"}
                    />
                    <button
                        type="button"
                        className="show-hide-btn"
                        onClick={() => setShowPassword(!showPassword)}
                    >
                        {showPassword ? <IoMdEyeOff /> : <IoMdEye /> }
                    </button>
                </div>

                <div className='w-full flex items-center justify-between mb-2 -mt-6'>
                    <div className='checkbox-div'>
                        <p onClick={() => setOtpLogin(true)} className='underline text-orange-600 text-xs font-medium'>
                            sign in with otp
                        </p>
                    </div>
                    <button 
                        onClick={() => setForgotPass(true)}
                        className='text-sm text-gray-600 text-opacity-70'
                    >
                        Forgot Password?
                    </button>
                </div>

                <button disabled={loader} type="submit" className="main-login-btn">
                    {loader? <Loader /> : "Login"}
                </button>

                <button onClick={goToSignUp} className='create-new-btn'>
                Create a new account
                </button>
            </form>
        </div>
    </div>
  )
}

export default LoginPage