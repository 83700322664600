import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from "react-router-dom"
import { useForm } from 'react-hook-form';
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import Background from '../Tutorial/component/Background'
import cameraInputImage from "../../assets/login/image-input.png"
import Navigator from '../../globalComponent/navigator/Navigator';
import { useMobile } from '../../globalComponent/context/MobileContext';
import "../../styles/component/signUp/SignUp.css"
import Camera from '../../globalComponent/camera/Camera';
import majorCities from '../../utils/data/majorCities';

const SignUp = () => {
    const { isMobile } = useMobile()
    const location = useLocation()
    const navigate = useNavigate()
    const phoneNumber = location.state?.phoneNumber;

    const { register, handleSubmit, formState: { errors }, setValue } = useForm()
    const [showPassword, setShowPassword] = useState(false)
    const [ openCamera, setOpenCamera ] = useState(false)
    const [ photos, setPhotos ] = useState()
    const [ errorMessage, setErrorMessage ] = useState()

    useEffect(() => {
        if (phoneNumber) {
            setValue("phNumber", phoneNumber); // Set the value of the phone number input
        }
    }, [phoneNumber, setValue]);


    useEffect(() => {
        if (errorMessage) {
          const timer = setTimeout(() => {
            setErrorMessage(null);
          }, 3000); // Set to 3 seconds
      
          // Clear the timeout if the component unmounts or errorMessage changes
          return () => clearTimeout(timer);
        }
      }, [errorMessage]);

    const onSubmit = (data) => {
        // console.log(data);
        if (data.password !== data.confirmPassword) {
            alert("passwords don't match!")
            return
        }

        const name = data.fullName.split(" ")
        if (!name[0] || !name[1]) {
            alert("Please enter full name")
            return
        }

        if (!photos) {
            alert("Photo is Mandatory")
            return
        }

        const loginData = {
            first_name: name[0],
            last_name: name[name.length-1],
            phone_number: data.phNumber,
            area: data.area,
            email: data.email,
            password: data.password,
            user_type: "driver",
            profile_photo: photos?.blob,
        }

        const sendData = {
            image: photos?.image,
            data: loginData
        }

        sessionStorage.setItem("signUp", JSON.stringify(sendData))

        navigate("/signup/car", { state: sendData })
    };

  return (
    <Background noBg={false}>
        <div className='sign-up-main-div'>

           {isMobile &&
            <div className='navigator-main-container'>
                <Navigator pageTitle={"Sign Up"}/>
            </div>}

            {openCamera &&
            <div className='w-screen h-screen fixed top-0 left-0 z-[999] bg-black bg-opacity-40 flex items-center justify-center'>
                <Camera
                    setPhotos={setPhotos}
                    setIsCameraVisible={setOpenCamera}
                />
            </div>}

            {!isMobile &&
            <div className='image-container p-10'>
                <div className='w-full aspect-square rounded-full overflow-hidden flex items-center justify-center'>
                    {photos? 
                    <img 
                        src={photos.image}
                        className='w-full h-full object-cover' 
                        onClick={() => setOpenCamera(true)}
                        alt='profile' 
                    /> : 
                    <img 
                        src={cameraInputImage}
                        className='hero-image' 
                        onClick={() => setOpenCamera(true)}
                        alt='profile' 
                    />}
                </div>
            </div>}

            <div className='divider'></div>

            <div className='main-form-container'>
                <p className='main-title supermercado'>
                    Sign Up
                </p>

                {isMobile &&
                <div className='image-container-mobile w-full aspect-square rounded-full overflow-hidden flex items-center justify-center'>
                    {photos? 
                    <img 
                        src={photos.image}
                        className='w-full h-full object-cover' 
                        onClick={() => setOpenCamera(true)}
                        alt='profile' 
                    /> : 
                    <img 
                        src={cameraInputImage}
                        className='hero-image' 
                        onClick={() => setOpenCamera(true)}
                        alt='profile' 
                    />}
                </div>}

                <form onSubmit={handleSubmit(onSubmit)}>
                <div className="w-full bg-[#E6FFE8] rounded-lg h-12 mb-4 overflow-hidden">
                    <input
                        type="text"
                        placeholder="Full Name"
                        {...register("fullName", { required: true })}
                        className={`w-full h-full bg-transparent rounded-lg text-xl text-black focus:outline-none px-5
                        ${errors.fullName ? 'border-red-600 border-2' : ""}`}
                    />
                </div>

                <div className="w-full bg-[#E6FFE8] rounded-lg h-12 mb-4 overflow-hidden">
                    <input
                        type="text"
                        placeholder="Phone Number"
                        {...register("phNumber", { required: true })}
                        className={`w-full h-full bg-transparent rounded-lg text-xl text-black focus:outline-none px-5
                        ${errors.phNumber ? 'border-red-600 border-2' : ""}`}
                    />
                </div>

                <div className="w-full bg-[#E6FFE8] rounded-lg h-12 mb-4 overflow-hidden">
                    <select
                        {...register("area", { required: true })}
                        className={`w-full h-full bg-transparent rounded-lg text-xl text-black focus:outline-none px-5
                        ${errors.area ? 'border-red-600 border-2' : ""}`}
                    >
                        <option value="">Select Area</option>
                        {majorCities.map(city => (
                            <option key={city} value={city}>{city}</option>
                        ))}
                    </select>
                    {errors.area && <span className="error-message">This field is required</span>}
                </div>

                <div className="w-full bg-[#E6FFE8] rounded-lg h-12 mb-4 overflow-hidden">
                    <input
                        type="email"
                        placeholder="Email"
                        {...register("email", { required: true })}
                        className={`w-full h-full bg-transparent rounded-lg text-xl text-black focus:outline-none px-5
                        ${errors.email ? 'border-red-600 border-2' : ""}`}
                    />
                </div>

                <div className="w-full bg-[#E6FFE8] rounded-lg h-12 mb-4 overflow-hidden relative">
                    <input
                        type={showPassword ? 'text' : 'password'}
                        placeholder="Password"
                        {...register("password", { required: true })}
                        className={`w-full h-full bg-transparent rounded-lg text-xl text-black focus:outline-none px-5
                        ${errors.password ? 'border-red-600 border-2' : ""}`}
                    />
                    <button
                        type="button"
                        className="absolute right-4 top-4 text-green-600 text-2xl"
                        onClick={() => setShowPassword(!showPassword)}
                    >
                        {!showPassword ? <IoMdEyeOff /> : <IoMdEye />}
                    </button>
                </div>

                <div className="w-full bg-[#E6FFE8] rounded-lg h-12 mb-4 overflow-hidden">
                    <input
                        type={showPassword ? 'text' : 'password'}
                        placeholder="Confirm Password"
                        {...register("confirmPassword", { required: true })}
                        className={`w-full h-full bg-transparent rounded-lg text-xl text-black focus:outline-none px-5
                        ${errors.confirmPassword ? 'border-red-600 border-2' : ""}`}
                    />
                </div>


                    <button type="submit" className="main-sign-up-btn supermercado">
                        Sign Up
                    </button>
                </form>

                <p className='login-text'>
                    Already have an account? <span onClick={() => navigate("/login")}>Sign In</span>
                </p>
            </div>
        </div>
    </Background>
  )
}

export default SignUp