import { IoClose } from "react-icons/io5"
import axiosInstance from "../../../utils/axiosInstance"
import { useState } from "react"
import { ThreeDots } from "react-loader-spinner"

const RechargePopUp = ({ userId, amount, setAmount, setRechargePopUp }) => {
    const [ loader, setLoader ] = useState(false)

    const handleClose = () => {
        setAmount(0)
        setRechargePopUp(false)
    }

    const handlePayment = () => {
        setLoader(true)
        const paymentDetails = {
          amount: Number(amount)
        }
    
        if (amount !== 0) {
          axiosInstance
            .post(`/phonepe/initiate-payment/${userId}/`, paymentDetails)
            .then(res => {
              console.log(res.data.data.instrumentResponse.redirectInfo.url)
              const redirectUrl = res.data.data.instrumentResponse.redirectInfo.url
              window.location.href = redirectUrl
            })
            .catch(err => {
              console.log(err)
            })
            .finally(() => {
                setLoader(false)
            })
        }
      };

    return (
        <div className='w-screen h-screen fixed top-0 left-0 z-50 flex items-center justify-center p-5 backdrop-blur-sm'>
            <div className='w-full relative max-w-[30rem] rounded-3xl bg-white p-6 shadow-green'>
                <button
                    onClick={handleClose}
                    className='absolute top-5 right-5 p-1 aspect-square flex items-center justify-cente rounded-full text-2xl'
                >
                    <IoClose />
                </button>
                <p className='text-center text-orange-600 font-semibold text-2xl supermercado'>
                    Enter Recharge Amount
                </p>
                <input
                    type='number'
                    className='w-full mt-6 mb-4 border-2 rounded-lg h-14 p-4 bg-white focus:outline-none'
                    value={amount}
                    onChange={e => setAmount(e.target.value)}
                />
                <div className='w-full flex justify-end'>
                    <button
                        onClick={handlePayment}
                        className='px-5 py-1 bg-orange-500 w-full h-14 text-2xl supermercado rounded-2xl flex items-center justify-center text-white'
                    >
                            {!loader? "Recharge" : <ThreeDots color="#ffffff" />}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default RechargePopUp;