import React, { useEffect, useRef, useState } from 'react'
import { StandaloneSearchBox } from '@react-google-maps/api'
import { useLoader } from '../context/LoaderContext';
import { useUserContext } from '../context/AppContext';

const LocationSelection = () => {
    const { isLoaded } = useLoader();

    const { setDriverArea, currentLocation, setCurrentLocation } = useUserContext()

    const inputRef = useRef(null)

    const handleOnPlacesChanged = () => {
        let address = inputRef.current.getPlaces()
        const places = inputRef.current.getPlaces();
        if (places.length > 0) {
            const place = places[0];
            const lat = place.geometry.location.lat();
            const lng = place.geometry.location.lng();

            console.log(places)
            setDriverArea(place.name)
            
            // Ensure the location is in India before setting it
            if (place.formatted_address.includes('India')) {
                setCurrentLocation({
                    address: place.formatted_address,
                    position: { lat, lng }
                });
            } else {
                alert('Please select a location within India.');
            }
        }
    }
    
    const searchOptions = {
        componentRestrictions: { country: "IN" }
    };

  return (
    <div className='w-full h-10'>
        {isLoaded && 
          <StandaloneSearchBox
            options={searchOptions}
            onLoad={(ref) => inputRef.current = ref}  
            onPlacesChanged={() => handleOnPlacesChanged()}
          >
            <input
              type='text'
              placeholder={`Enter Location`}
              className="w-full h-10 rounded-full px-5 bg-orange-600 md:bg-white max-w-[50rem] bg-opacity-20 focus:outline-none backdrop-blur-sm"
              value={currentLocation.address}
              onChange={e => setCurrentLocation(prev => ({ ...prev, address: e.target.value}))}
            />
          </StandaloneSearchBox>}
    </div>
  )
}

export default LocationSelection