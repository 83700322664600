import React, { useEffect, useState } from 'react';
import { APIProvider } from '@vis.gl/react-google-maps';
import { Rings } from 'react-loader-spinner';
import axios from 'axios';

// Custom components
import NewOrderCard from '../../globalComponent/ui/NewOrderCard';
import OfferYourFarePopUp from '../../globalComponent/ui/OfferYourFarePopUp';
import GoogleMapBg from '../../globalComponent/google/GoogleMapBg';
import RideStart from './components/RideStart';

// Utilities and contexts
import axiosInstance from '../../utils/axiosInstance';
import { useUserContext } from '../../globalComponent/context/AppContext';
import rideStatusValues from './data/rideStatusValues';

// Styles
import "../../styles/component/driver/DriverHome.css";

// Component for displaying when there are no orders
const NoOrder = () => (
  <div className='w-full max-w-[30rem] p-5 flex flex-col items-center justify-center gap-3 bg-white z-10 rounded-3xl shadow-2xl'>
    <p className='text-[10rem] text-green-700 text-opacity-90'>
      {/* TODO: Add an icon here, e.g., <TbMoodSad /> */}
    </p>
    <p className='text-xl font-light text-opacity-40 text-black'>
      Waiting For Orders, Refresh to view
    </p>
  </div>
);

const DriverHome = () => {
  // Context and session data
  const { setDriverArea, currentLocation, setCurrentLocation, setDeviceLocation } = useUserContext();
  const userId = sessionStorage.getItem("userId");
  const driverId = sessionStorage.getItem("driverId");
  const currentRide = JSON.parse(sessionStorage.getItem("ride"));
  const riding = sessionStorage.getItem("riding");

  // State variables
  const [ allOrders, setAllOrders] = useState([]);
  const [ currentOrderIndex, setCurrentOrderIndex] = useState(0);
  const [ loader, setLoader] = useState(false);
  const [ offerPrice, setOfferPrice] = useState(Number);
  const [ showOfferWindow, setShowOfferWindow] = useState(false);
  const [ map, setMap] = useState(null);
  const [ distance, setDistance] = useState(null);
  const [ directions, setDirections] = useState(null);
  const [ rideStart, setRideStart] = useState(false);
  const [ rideStatus, setRideStatus] = useState(sessionStorage.getItem("rideStatus"));
  const [ refreshClicked, setRefreshClicked] = useState(false);
  const [ pickUpLocation, setPickUpLocation] = useState({ address: "", position: { lat: null, lng: null } });
  const [ dropLocation, setDropLocation] = useState({ address: "", position: { lat: null, lng: null } });

  useEffect(() => {
    sessionStorage.setItem("rideStatus", rideStatus)
  }, [rideStatus])

  // Update driver's current location in the backend
  useEffect(() => {
    if (currentLocation.position.lat && currentLocation.position.lng) {
      const driverPosition = {
        latitude: `${currentLocation.position.lat}`,
        longitude: `${currentLocation.position.lng}`
      };

      axiosInstance.post(`driver/driverlocation/${userId}/`, driverPosition)
        .then(res => console.log(res))
        .catch(err => console.log(err));
    }
  }, [currentLocation, userId]);

  // Get address from coordinates using Google Maps API
  const getAddress = async (lat, lng) => {
    setLoader(true);
    try {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`
      );
      setDeviceLocation(true)
      const address = response.data.results[0].formatted_address;
      setDriverArea(response.data.results[3].address_components[1].short_name);
      setCurrentLocation(prev => ({ ...prev, address }));
    } catch (error) {
      console.error("Error fetching location", error);
    } finally {
      setLoader(false);
    }
  };

  // Fetch current location of the driver
  const fetchCurrentLocation = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        getAddress(position.coords.latitude, position.coords.longitude);
        setCurrentLocation(prev => ({
          ...prev,
          position: {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          }
        }));
      },
      (error) => {
        if (error.code === error.PERMISSION_DENIED) {
          setLoader(false);
          // alert("Location services are disabled. Please enable location services to move forward.");
        } else {
          console.error(error);
        }
      }
    );
  };

  useEffect(() => {
    if (!rideStatus) {
      setRideStatus(rideStatusValues.searching)
    }
  }, [rideStatus])

  // Initialize current location
  useEffect(() => {
    fetchCurrentLocation();
  }, []);

  // Handle ongoing ride
  useEffect(() => {
    if (riding && currentRide) {
      setPickUpLocation({
        address: currentRide.pickup_location,
        position: {
          lat: Number(currentRide.pickup_lat),
          lng: Number(currentRide.pickup_lng)
        }
      });

      setDropLocation({
        address: currentRide.drop_location,
        position: {
          lat: Number(currentRide.drop_lat),
          lng: Number(currentRide.drop_lng)
        }
      });

      setRideStart(true);
      !rideStatus && setRideStatus(rideStatusValues.reaching);
    }
  }, []);

  // Fetch all available orders for the driver
  useEffect(() => {
    const timer = setInterval(() => {
      if (!riding && allOrders.length === 0) {
        axiosInstance.get(`/bargaining/driversGet/${userId}/`)
          .then(res => setAllOrders(res.data))
          .catch(err => console.error(err))
      }
    }, 1000)

    return () => clearInterval(timer)
  }, [userId]);

  // Update pickup and drop locations when the current order changes
  useEffect(() => {
    if (allOrders[currentOrderIndex] && !riding) {
      setPickUpLocation({
        address: allOrders[currentOrderIndex].pickup_location,
        position: {
          lat: Number(allOrders[currentOrderIndex].pickup_lat),
          lng: Number(allOrders[currentOrderIndex].pickup_lng)
        }
      });

      setDropLocation({
        address: allOrders[currentOrderIndex].drop_location,
        position: {
          lat: Number(allOrders[currentOrderIndex].drop_lat),
          lng: Number(allOrders[currentOrderIndex].drop_lng)
        }
      });
    }
  }, [currentOrderIndex, allOrders]);

  // Accept the current order
  const handleAcceptOrder = () => {
    const postData = {
      userRide: `${allOrders[currentOrderIndex].RideCreated}`,
      driveroffer: (offerPrice && offerPrice > 50) ? offerPrice : allOrders[currentOrderIndex].offer_fare
    };

    setRideStart(true);
    setRideStatus(rideStatusValues.reaching);
    setLoader(true);

    axiosInstance.post(`/bargaining/Driverfare/${userId}/`, postData)
      .then(res => {
        sessionStorage.setItem("ride", JSON.stringify(allOrders[currentOrderIndex]));
        sessionStorage.setItem("riding", true);
      })
      .catch(err => console.error(err))
      .finally(() => setLoader(false));
  };

  // Cancel the current order and move to the next one
  const handleCancelOrder = () => {
    if (currentOrderIndex < allOrders.length - 1) {
      setCurrentOrderIndex(prev => prev + 1);
    } else {
      alert("There are no other orders");
    }
  };

  // Refresh available orders
  const handleRefresh = () => {
    setRefreshClicked(true);
    axiosInstance.get(`/bargaining/driversGet/${userId}/`)
      .then(res => setAllOrders(res.data))
      .catch(err => console.error(err))
      .finally(() => setRefreshClicked(false));
  };

  return (
    <APIProvider 
      apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
      onLoad={() => console.log('Maps API has loaded.')}
    >
      <div className='h-full w-full bg-white rounded-2xl border-2 border-[#F95C29] flex items-center justify-center relative overflow-hidden'>
        {/* Loader */}
        {loader && 
          <div className='w-full h-full flex items-center justify-center absolute z-50 pointer-events-none top-0 left-0'>
            <Rings
              visible={true}
              height="80"
              width="80"
              color="#F95C29"
              ariaLabel="rings-loading"
            />
          </div>
        }

        {/* Refresh button */}
        {rideStatus === rideStatusValues.searching &&
          <button
            onClick={handleRefresh}
            disabled={refreshClicked}
            className='px-4 py-2 z-50 disabled:opacity-30 absolute top-2 right-2 text-sm font-semibold bg-white rounded-xl shadow-xl'
          >
            Refresh
          </button>
        }

        {/* Offer window popup */}
        {showOfferWindow &&
          <OfferYourFarePopUp
            offerPrice={offerPrice}
            setOfferPrice={setOfferPrice}
            amount={allOrders[currentOrderIndex].offer_fare}
            setShowOfferPopUp={setShowOfferWindow}
            handleAcceptOrder={handleAcceptOrder}
          />
        }

        {/* Display orders or NoOrder component */}
        {!loader && !rideStart &&
          <div className='w-full h-full flex flex-col items-center justify-end p-4'>
            {allOrders.length > 0 ? (
              <NewOrderCard
                pickup={allOrders[currentOrderIndex].pickup_location}
                dropOff={allOrders[currentOrderIndex].drop_location}
                amount={allOrders[currentOrderIndex].offer_fare}
                type={allOrders[currentOrderIndex].type}
                parcel={allOrders[currentOrderIndex]}
                distance={distance}
                setCurrentOrderIndex={setCurrentOrderIndex}
                setShowOfferWindow={setShowOfferWindow}
                handleAcceptOrder={handleAcceptOrder}
                handleCancelOrder={handleCancelOrder}
              />
            ) : <NoOrder />}
          </div>
        }

        {/* Ride start component */}
        {rideStart &&
          <RideStart
            current={currentLocation}
            pickup={pickUpLocation}
            drop={dropLocation}
            refresh={fetchCurrentLocation}
            rideStatus={rideStatus}
            setRideStatus={setRideStatus}
          />
        }

        {/* Google Maps background */}
        <GoogleMapBg
          rideStatus={rideStatus}
          pickUpLocation={pickUpLocation}
          dropLocation={dropLocation}
          currentLocation={currentLocation}
          driverLocation={currentLocation}
          directions={directions}
          setDirections={setDirections}
          setDistance={setDistance}
          map={map}
          setMap={setMap}
        />
      </div>
    </APIProvider>
  );
};

export default DriverHome;