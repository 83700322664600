import { createContext, useContext, useEffect, useState } from 'react';

const AppContext = createContext();

export const UserProvider = ({ children }) => {
  const [ name, setName] = useState('');
  const [ userId, setUserId ] = useState()
  const [ loggedIn, setLoggedIn ] = useState(false)
  const [ userData, setUserData ] = useState(JSON.parse(sessionStorage.getItem("userData")))
  const [ alert, setAlert ] = useState()
  const [ driverArea, setDriverArea ] = useState()
  const [ deviceLocation, setDeviceLocation ] = useState(false)
  const [ currentLocation, setCurrentLocation] = useState({ address: "", position: { lat: null, lng: null } });

  const location = JSON.parse(sessionStorage.getItem("location"))

  useEffect(() => {
    if (location) {
      setCurrentLocation(location)
      setDriverArea(location.address)
      setDeviceLocation(true)
    }
  }, [])

  useEffect(() => {
    if (currentLocation.address) {
      sessionStorage.setItem("location", JSON.stringify(currentLocation))
    }
  }, [currentLocation])

  useEffect(() => {
    const timer = setTimeout(() => {
      console.log(alert)
      setAlert(null);
    }, 5000);
  
    // Cleanup function to clear the timeout
    return () => clearTimeout(timer);
  }, [alert]);

  useEffect(() => {
    if (userId) {
        sessionStorage.setItem("userId", userId)
    }
  }, [userId])

  return (
    <AppContext.Provider
      value={{
        name,
        setName,
        userId,
        setUserId,
        loggedIn,
        setLoggedIn,
        userData,
        setUserData,
        alert,
        setAlert,
        driverArea,
        setDriverArea,
        currentLocation,
        setCurrentLocation,
        deviceLocation,
        setDeviceLocation
      }}
    >
        {console.log(alert)}
      {children}
    </AppContext.Provider>
  );
};

export const useUserContext = () => useContext(AppContext);
