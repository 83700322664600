import React, { useEffect, useState } from 'react';
import { IoMenu } from "react-icons/io5";
import axiosInstance from '../../../utils/axiosInstance';
import rideStatusValues from '../data/rideStatusValues';

// Menu component
const Menu = () => {
    const handleClick = () => {
        sessionStorage.removeItem("ride");
        window.location.reload();
    };
    
    return (
        <div className='w-[7rem] h-full shadow-xl rounded-lg bg-white text-left text-base font-medium p-2 flex flex-col items-start justify-start gap-2'>
            <button className='bg-black bg-opacity-10 w-full text-left px-2 rounded-md py-1'>
                Chat
            </button>
            <button className='bg-black bg-opacity-10 w-full text-left px-2 rounded-md py-1'>
                Call
            </button>
            <button onClick={handleClick} className='mt-2 text-red-500'>
                Cancel
            </button>
        </div>
    );
};

// Main RideStart component
const RideStart = ({ current, pickup, drop, refresh, setRideStatus, rideStatus }) => {
    const [inPickupRange, setInPickupRange] = useState(false);
    const [inDropRange, setInDropRange] = useState(false);
    const [clicked, setClicked] = useState(false);
    const [showMenu, setShowMenu] = useState(false);
    const driverId = sessionStorage.getItem("driverId");

    // Calculate distance between two coordinates using Haversine formula
    const haversineDistance = (coords1, coords2) => {
        const toRadians = degrees => degrees * (Math.PI / 180);
        const R = 6371e3; // Earth's radius in meters
        const lat1 = toRadians(coords1.lat);
        const lat2 = toRadians(coords2.lat);
        const deltaLat = toRadians(coords2.lat - coords1.lat);
        const deltaLng = toRadians(coords2.lng - coords1.lng);

        const a = Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
                  Math.cos(lat1) * Math.cos(lat2) *
                  Math.sin(deltaLng / 2) * Math.sin(deltaLng / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

        return R * c; // Distance in meters
    };

    // Check if coordinates are within 50 meters
    const areCoordinatesWithin50Meters = (coords1, coords2) => {
        return haversineDistance(coords1, coords2) <= 50;
    };

    // Reset clicked state after 2 seconds
    useEffect(() => {
        if (clicked) {
            const timer = setTimeout(() => setClicked(false), 2000);
            return () => clearTimeout(timer);
        }
    }, [clicked]);

    // Check if driver is in pickup range
    useEffect(() => {
        const reached = areCoordinatesWithin50Meters(pickup.position, current.position);
        if (inPickupRange !== reached) {
            setInPickupRange(reached);
        }
    }, [current, pickup]);
    
    // Check if driver is in drop range
    useEffect(() => {
        const reached = areCoordinatesWithin50Meters(drop.position, current.position);
        if (inDropRange !== reached) {
            setInDropRange(reached);
        }
    }, [current, drop]);

    // Handle refresh button click
    const handleRefresh = () => {
        refresh();
        setClicked(true);
    };

    // Handle start ride
    const handleStart = () => {
        setRideStatus(rideStatusValues.dropping);
        updateDriverStatus("ON_THE_WAY");
    };

    // Handle reached destination
    const handleReached = () => {
        updateDriverStatus("ARRIVED");
    };

    // Update driver status
    const updateDriverStatus = (status) => {
        axiosInstance.put(`/logistics/driver/${driverId}/`, { status })
            .then(res => {
                console.log(res);
                if (status === "ARRIVED") {
                    setRideStatus(rideStatusValues.reached);
                }
            })
            .catch(err => console.error(err));
    };

    return (
        <div className='w-full h-full relative flex flex-col z-30 items-center justify-end p-2 pointer-events-auto'>
            <StatusBar inPickupRange={inPickupRange} rideStatus={rideStatus} />
            <ControlButtons 
                handleRefresh={handleRefresh} 
                clicked={clicked} 
                showMenu={showMenu} 
                setShowMenu={setShowMenu} 
            />
            <ActionButtons 
                rideStatus={rideStatus} 
                inPickupRange={inPickupRange} 
                inDropRange={inDropRange} 
                handleStart={handleStart} 
                handleReached={handleReached} 
            />
        </div>
    );
};

// Status bar component
const StatusBar = ({ inPickupRange, rideStatus }) => (
    <div className='bg-white bg-opacity-70 absolute top-0 left-0 shadow-2xl w-full h-10 flex items-center justify-center'>
        <p className='text-sm text-green-600 animate-pulse text-center font-semibold'>
            {(!inPickupRange && rideStatus === rideStatusValues.reaching)
                ? "Please reach the pickup location and take the parcel to click start"
                : "Click start and begin the ride"
            }
        </p>
    </div>
);

// Control buttons component
const ControlButtons = ({ handleRefresh, clicked, showMenu, setShowMenu }) => (
    <div className='h-10 absolute top-12 right-2 flex items-center justify-center gap-2'>
        <button
            onClick={handleRefresh}
            disabled={clicked}
            className='px-4 py-2 disabled:opacity-30 text-sm font-semibold bg-white rounded-xl shadow-xl'
        >
            Refresh
        </button>
        <div className='w-fit h-fit relative'>
            <button
                onClick={() => setShowMenu(prev => !prev)}
                className='h-10 relative aspect-square bg-white shadow-xl flex items-center justify-center rounded-full text-2xl'
            >
                <IoMenu />
            </button> 
            {showMenu && (
                <div className='absolute top-[120%] right-0'>
                    <Menu />
                </div>
            )}
        </div>
    </div>
);

// Action buttons component
const ActionButtons = ({ rideStatus, inPickupRange, inDropRange, handleStart, handleReached }) => (
    <div className='w-full flex items-center justify-center gap-2 relative'>
        {rideStatus === rideStatusValues.reaching && (
            <button
                onClick={handleStart}
                className='bg-blue-500 w-1/2 h-10 flex items-center justify-center text-white disabled:bg-gray-500 rounded-full text-xl font-semibold'
            >
                Start
            </button>
        )}
        {rideStatus === rideStatusValues.dropping && (
            <button
                disabled={!inDropRange}
                onClick={handleReached}
                className='bg-green-500 w-full h-10 flex items-center justify-center text-white disabled:bg-gray-500 rounded-full text-xl font-semibold'
            >
                Reached
            </button>
        )}
    </div>
);

export default RideStart;