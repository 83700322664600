import { useEffect, useState } from "react"
import { IoClose } from "react-icons/io5"

const OfferYourFarePopUp = ({ offerPrice, setOfferPrice, amount, setShowOfferPopUp, handleAcceptOrder }) => {
    const [ disabled, setDisabled ]  = useState(true)

    const handleClose = () => {
        setOfferPrice(Number)
        setShowOfferPopUp(false)
    }

    function isWithinRange(offerPrice, estimate ) {
        const lowerBound = estimate * 0.5;
        const upperBound = estimate * 1.5;
    
        return offerPrice >= lowerBound && offerPrice <= upperBound;
    }

    useEffect(() => {
        if (isWithinRange(offerPrice, amount)) {
            setDisabled(false)
        } else {
            setDisabled(true)
        }
    }, [offerPrice, amount])

    return (
        <div className='w-screen h-screen fixed top-0 left-0 z-50 flex items-center justify-center p-5 backdrop-blur-sm'>
            <div className='w-full relative max-w-[30rem] rounded-3xl bg-white p-6 shadow-2xl'>
                <button
                    onClick={handleClose}
                    className='absolute top-5 right-5 p-1 aspect-square flex items-center justify-cente rounded-full text-2xl'
                >
                    <IoClose />
                </button>
                <p className='text-center text-orange-600 font-semibold text-2xl'>
                    Offer Your Fare
                </p>
                <p className="text-center mt-2 font-light text-sm">
                    Estimated Amount:  {amount}
                </p>
                <input
                    type='number'
                    className='w-full mt-6 mb-4 border-2 rounded-lg h-14 p-4 bg-white focus:outline-none'
                    value={offerPrice}
                    onChange={e => setOfferPrice(e.target.value)}
                    min={50}
                />
                <div className='w-full flex justify-end'>
                    <button
                        disabled={disabled? true : false}
                        onClick={handleAcceptOrder}
                        className='px-5 py-1 bg-green-700 disabled:bg-gray-800 disabled:bg-opacity-70 rounded-2xl flex items-center justify-center text-white'
                    >
                            Submit
                    </button>
                </div>
            </div>
        </div>
    )
}

export default OfferYourFarePopUp;