import { GoogleMap, Marker, Polyline } from '@react-google-maps/api'
import React, { useEffect, useState } from 'react'
import mapStyles from './mapStyles';
import redPin from "../../assets/icons/red-pin.svg"
import greenPin from "../../assets/icons/green-pin.svg"
import blueDot from "../../assets/icons/blue-dot.svg"
import rideStatusValues from '../../components/driverPages/data/rideStatusValues';
import { useLoader } from '../context/LoaderContext';

const GoogleMapBg = ({
    pickUpLocation,
    rideStatus,
    dropLocation,
    currentLocation,
    directions,
    setDirections,
    setDistance,
    map,
    setMap
}) => {
    const { isLoaded } = useLoader();

    const [ origin, setOrigin ] = useState({
        lat: null,
        lng: null
    })
    const [ destination, setDestination ] = useState({
        lat: null,
        lng: null
    })

    useEffect(() => {
        if (rideStatus === rideStatusValues.searching) {
            setOrigin(pickUpLocation.position)
            setDestination(dropLocation.position)
            return
        } else if (rideStatus === rideStatusValues.reaching) {
            setOrigin(currentLocation.position)
            setDestination(pickUpLocation.position)
            return
        } else if (rideStatus === rideStatusValues.dropping) {
            setOrigin(currentLocation.position)
            setDestination(dropLocation.position)
        }
    }, [rideStatus, currentLocation, dropLocation, pickUpLocation])

    const calculateDistance = async () => {
        const directionsService = new window.google.maps.DirectionsService();
        directionsService.route(
            {
                origin,
                destination,
                travelMode: window.google.maps.TravelMode.DRIVING,
            },
            (result, status) => {
                if (status === window.google.maps.DirectionsStatus.OK) {
                    setDirections(result);
                    const distanceInMeters = result.routes[0].legs[0].distance.value;
                    setDistance(distanceInMeters / 1000); // Convert to kilometers

                    const bounds = new window.google.maps.LatLngBounds();
                    bounds.extend(origin);
                    bounds.extend(destination);
                    map.fitBounds(bounds, {
                        top: 20,
                        left: 0,
                        bottom: 100,
                        right: 0, // Add padding to the right
                    }); // Adjust the map's viewport to show the full route
                } else {
                    console.error(`Error fetching directions: ${status}`);
                }
            }
        );
    };

    // calculate distance and get route
    useEffect(() => {
        // if (origin.lat && origin.lng && destination.lat && destination.lng) {
            isLoaded && calculateDistance();
        // }
    }, [origin, destination, isLoaded]);

  return (
    <div className='w-full absolute top-0 left-0 h-full pointer-events-all'>
        {isLoaded &&
        <GoogleMap
            zoom={13}
            onLoad={mapInstance => setMap(mapInstance)}
            mapContainerStyle={{ width: "100%", height: "100%" }}
            center={currentLocation.position}
            // onUnmount={onUnmount}
            options={{
                styles: mapStyles,
                disableDefaultUI: true,
            }}
            >
                {directions && (
                    <Polyline
                        path={directions.routes[0].overview_path}
                        options={{
                            strokeColor: "#FFA500", // Orange color for pathline
                            strokeOpacity: 1,
                            strokeWeight: 3,
                        }}
                    />
                )}

                {pickUpLocation.address !== "" && rideStatus !== rideStatusValues.dropping &&
                <Marker
                    position={pickUpLocation.position}
                    icon={{
                        url: greenPin,
                        scaledSize: new window.google.maps.Size(30, 30),
                        origin: new window.google.maps.Point(0, 0),
                        anchor: new window.google.maps.Point(15, 28)
                    }}
                    label={{
                        text: ".",
                        color: "#16a34a"
                    }}
                />}

                {currentLocation.address !== "" &&
                <Marker
                    position={currentLocation.position}
                    icon={{
                        url: blueDot,
                        scaledSize: new window.google.maps.Size(100,100),
                        origin: new window.google.maps.Point(0, 0),
                        anchor: new window.google.maps.Point(50, 50)
                    }}
                    label={{
                        text: ".",
                        color: "#4343ff"
                    }}
                />}

                {dropLocation.address !== "" && rideStatus !== rideStatusValues.reaching &&
                <Marker
                    position={dropLocation.position}
                    icon={{
                        url: redPin,
                        scaledSize: new window.google.maps.Size(30, 30),
                        origin: new window.google.maps.Point(0, 0),
                        anchor: new window.google.maps.Point(15, 28)
                    }}
                    label={{
                        text: ".",
                        color: "#dc2626"
                    }}
                />}
        </GoogleMap>}
    </div>
  )
}

export default GoogleMapBg