import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { MdLocationPin } from "react-icons/md";
import tookiLogo from "../assets/logo/tookiLogo.png"
import profilePlaceholder from "../assets/placeholder/profile-placeholder.png"
import DriverHome from '../components/driverPages/DriverHome'
import { IoChevronDown } from "react-icons/io5";
import "../styles/pages/DriverHomePage.css"
import { useUserContext } from '../globalComponent/context/AppContext';
import LocationSelection from '../globalComponent/ui/LocationSelection';

const DriverHomePage = () => {
    const navigate = useNavigate()

    const { driverArea, deviceLocation } = useUserContext()

    const [ showLocationSelection, setShowLocationSelection ] = useState()

  return (
    <div className='w-screen h-screen flex items-center justify-center gap-3 flex-col px-6 py-12 pb-24 orange-gradient'>

        <div className='w-full h-12 md:h-20 flex items-center justify-between md:bg-white md:px-2 md:pl-4 rounded-full md:py-2 md:shadow-2xl'>
            <div onClick={() => navigate("/driver")} className='h-full aspect-square'>
                <img 
                    src={tookiLogo} 
                    className='w-full h-full' 
                    alt='tooki' 
                />
            </div>

            <button 
                disabled={deviceLocation}
                onClick={() => setShowLocationSelection(prev => !prev)}
                className='flex items-center justify-between max-w-[10rem] w-fit h-10 rounded-full bg-[#28C928] bg-opacity-20 border-2 border-[#28c928] box-border p-1 pl-2 shadow-[0px_4px_8px_0px_rgba(0,0,0,0.11)] gap-[2px]'
            >
                <p className='text-green-600 text-xl'>
                    <MdLocationPin />
                </p>
                {driverArea?
                <p className="text-nowrap text-green-600 text-xs overflow-hidden">
                    {driverArea}
                </p> :
                <p className='animate-pulse text-green-600 text-xs px-3'>
                    fetching...
                </p>}
                <p className='text-green-600 text-sm pr-1'>
                    <IoChevronDown />
                </p>
            </button>

            <div className='h-full aspect-square'>
                <img 
                    src={profilePlaceholder} 
                    className='w-full h-full' 
                    alt='helloooo'
                    onClick={() => navigate("/profile")}
                />
            </div>
        </div>

        {showLocationSelection &&
        <LocationSelection
            
        />}
        
        <div className='w-full h-full md:px-20'>
            <DriverHome />
        </div>
    </div>
  )
}

export default DriverHomePage