import React, { useContext, useEffect, useState } from 'react'
import CurrentOrderCard from '../../globalComponent/ui/CurrentOrderCard'
import EarnedTodayCard from '../../globalComponent/ui/EarnedTodayCard'
import "../../styles/component/driver/DriverProfile.css"
import { GlobalStateContext } from '../../utils/GlobalState'
import axiosInstance from '../../utils/axiosInstance'
import { useNavigate } from 'react-router-dom'

const DriverProfile = () => {
  const navigate = useNavigate()
  const { state } = useContext(GlobalStateContext)
  const [ earning, setEarning ] = useState()

  useEffect(() => {
    axiosInstance
      .put(`/analytics/data/driver/earning/${state.userId}/`)
      .then(res => {
        const response = res.data.data
        setEarning(response)
      })
      .catch(err => console.log(err))
  }, [state])

  const handleLogOut = () => {
    sessionStorage.clear()
    localStorage.clear()
    
    navigate("/")
  }

  return (
    <div className='w-full relative'>
        <div className='absolute top-0 left-0 tooki-gradient w-full h-80 -z-10'>
        </div>

      <div className='w-full pt-10 p-5'>
        <div className='w-full flex items-center justify-center'>
          <div className='flex max-w-[50rem] items-center justify-between w-full'>

            <p className='text-3xl font-semibold supermercado'>
              Profile
            </p>

            <button
              onClick={handleLogOut}
              className='bg-white py-2 px-4 text-sm font-semibold shadow-2xl rounded-lg flex items-center justify-center'
            >
              Log Out
            </button>
          </div>
        </div>

        <div className='current-trip-card-container flex items-center justify-center'>
            <CurrentOrderCard customerName={state.name} />
        </div>

        <div className='earnings-today-card mt-10 z-10 flex items-center justify-center'>
            <EarnedTodayCard earning={earning && earning[0].total_amount}/>
        </div>
      </div>
    </div>
  )
}

export default DriverProfile