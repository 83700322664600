import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from "react-router-dom"
import { useForm } from 'react-hook-form';
import { MdCloudUpload } from "react-icons/md";
import { IoCloudDoneOutline } from "react-icons/io5";
import Background from '../Tutorial/component/Background'
import cameraInputImage from "../../assets/login/image-input.png"
import Navigator from '../../globalComponent/navigator/Navigator';
import { useMobile } from '../../globalComponent/context/MobileContext';
import axiosInstance from '../../utils/axiosInstance';
import "../../styles/component/signUp/SignUp.css"
import { ThreeDots } from 'react-loader-spinner';

const SignUpCarDetails = () => {
    const location = useLocation()
    const { isMobile } = useMobile()
    const navigate = useNavigate()
    const { register, handleSubmit, formState: { errors } } = useForm()
    const [ fileUploaded, setFileUploaded ] = useState()
    const [ loader, setLoader ] = useState(false)
    const [ errorMessage, setErrorMessage ] = useState()

    const registerUserData = location.state

    useEffect(() => {
        // console.log(fileUploaded)
        console.log(registerUserData)
    }, [registerUserData])

    useEffect(() => {
        if (errorMessage) {
          const timer = setTimeout(() => {
            setErrorMessage(null);
          }, 3000); // Set to 3 seconds
      
          // Clear the timeout if the component unmounts or errorMessage changes
          return () => clearTimeout(timer);
        }
      }, [errorMessage]);

    const onSubmit = (data) => {
        setLoader(true)
        console.log(data.policeVerification[0])
        const formData = new FormData();

        console.log(registerUserData)
    
        // Append fields from registerUserData
        Object.keys(registerUserData.data).forEach(key => {
            formData.append(key, registerUserData.data[key]);
        });
    
        // Append fields from data
        formData.append('license_no', data.dlNumber);
        formData.append('aadhaar_no', data.aadhaar);
        formData.append('police_verification_letter', data.policeVerification[0]);
    
        axiosInstance
            .post("/register", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(res => {
                console.log(res);
                setLoader(false)
                navigate("/login");
                sessionStorage.removeItem("signUp")
            })
            .catch(err => {
                console.log(err);
                setLoader(false)
                sessionStorage.removeItem("signUp")
                const errors = err.response.data.data;
                const allMessages = [];
                
                for (let field in errors) {
                    if (errors.hasOwnProperty(field)) {
                        allMessages.push(`${field}: ${errors[field].join(', ')}`);
                    }
                }

                setErrorMessage(allMessages); 
            });
    };

  return (
    <Background noBg={false}>
        <div className='sign-up-main-div relative'>

           {isMobile &&
            <div className='navigator-main-container'>
                <Navigator pageTitle={"Sign Up"}/>
            </div>}

            {errorMessage?.length > 0 && (
            <ul className='p-3 max-w-[90%] px-2 fixed text-left bg-orange-500 rounded-2xl top-5 left-1/2 -translate-x-1/2 z-50 text-white'>
                {errorMessage?.map((msg, idx) => (
                    <li className='w-full text-sm font-medium text-nowrap capitalize' key={idx}>{msg}</li>
                ))}
            </ul>)}

            {!isMobile &&
            <div className='image-container p-10'>
                <div className='w-full aspect-square rounded-full overflow-hidden flex items-center justify-center'>
                    {registerUserData.image? 
                    <img 
                        src={registerUserData.image}
                        className='w-full h-full object-cover' 
                        // onClick={() => setOpenCamera(true)}
                        alt='tutorial' 
                    /> : 
                    <img 
                        src={cameraInputImage}
                        className='hero-image' 
                        // onClick={() => setOpenCamera(true)}
                        alt='tutorial' 
                    />}
                </div>
            </div>}

            <div className='divider'></div>

            <div className='main-form-container'>
                <p className='main-title'>
                    Sign Up
                </p>

                {isMobile &&
                <div className='image-container-mobile w-full aspect-square rounded-full overflow-hidden flex items-center justify-center'>
                    {registerUserData.image? 
                    <img 
                        src={registerUserData.image}
                        className='w-full h-full object-cover' 
                        alt='tutorial' 
                    /> : 
                    <img 
                        src={cameraInputImage}
                        className='hero-image' 
                        alt='tutorial' 
                    />}
                </div>}

                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-group">
                        <input
                            type="text"
                            placeholder='Driver License No.'
                            {...register("dlNumber", { required: true })}
                            className={errors.fullName? 'form-control input-error' : "form-control"}
                        />
                    </div>
                    <div className="form-group">
                        <input
                            type="text"
                            placeholder='Aadhaar Card No.'
                            {...register("aadhaar", { required: true })}
                            className={errors.aadhaar? 'form-control input-error' : "form-control"}
                        />
                    </div>
                    
                    <div className="form-group file-input-group">
                        <p className='label-file-input'>
                            Upload Police Verification Letter
                        </p>
                        <input
                            type="file"
                            {...register("policeVerification", { required: true })}
                            onChange={e => setFileUploaded(e.target.value)}
                            className={errors.policeVerification? 'form-control input-error' : "form-control"}
                        />
                        {!fileUploaded &&
                        <div className='upload-icon'>
                            <MdCloudUpload />
                        </div>}
                        
                        {fileUploaded && 
                        <div className='upload-icon'>
                            <IoCloudDoneOutline />
                            <p className='upload-text'>
                                Upload Successful
                            </p>
                        </div>}
                    </div>

                    <button type="submit" className="main-sign-up-btn">
                        {!loader? "Sign Up" : <ThreeDots color='#ffffff' />}
                    </button>
                </form>

                <p className='login-text'>
                    Already have an account? <span onClick={() => navigate("/login")}>Sign In</span>
                </p>
            </div>
        </div>
    </Background>
  )
}

export default SignUpCarDetails