import React, { useState } from 'react'
import Login from '../components/login/Login'
import LoginMobile from '../components/login/LoginMobile'
import { useMobile } from '../globalComponent/context/MobileContext'
import { Navigate } from 'react-router-dom'
import LoginOtp from '../components/login/LoginOtp'

const LoginPage = () => {
  const { isMobile } = useMobile()
  const logged = sessionStorage.getItem("logged")
  const [ otpLogin, setOtpLogin ] = useState(true)

  if (logged) {
    return <Navigate to="/driver" />; // redirect to dashboard if logged in
  }

  return (
    <>
      {otpLogin && <LoginOtp setOtpLogin={setOtpLogin} />}

      {!otpLogin && 
        (isMobile? 
          <LoginMobile 
            setOtpLogin={setOtpLogin}
          /> : 
          <Login 
            setOtpLogin={setOtpLogin} 
          />
        )
      }
    </>
  )
}

export default LoginPage