import React, { useState } from 'react'
import { IoMdChatbubbles } from "react-icons/io";
import { RxCross1 } from "react-icons/rx";
import { IoCheckmark } from "react-icons/io5";
import "../../styles/globalComponent/NewOrderCard.css"

const NewOrderCard = ({ pickup, dropOff, parcel, distance, amount, setShowOfferWindow, handleAcceptOrder, handleCancelOrder }) => {
    const [ viewParcel, setViewParcel ] = useState(false)

  return (
    <div className='z-20 max-w-[40rem] bg-white w-full py-3 px-5 border-2 rounded-lg shadow-2xl'>

        {viewParcel &&
        <div
            onClick={() => setViewParcel(false)}
            className='w-screen h-screen flex items-center p-10 justify-center backdrop-blur-sm z-50 fixed top-0 left-0'
        >
            <img
                src={parcel.parcel_image}
                className='max-w-full max-h-full object-contain'
                alt='parcel'
            />
        </div>}

            <div className='w-full flex mb-1  items-center justify-between gap-5'>
                <p className='font-medium text-green-700'>
                    From:
                </p>
                <p className='text-sm font-light w-full text-nowrap overflow-hidden text-left'>
                    {pickup}
                </p>
            </div>
            <div className='w-full mb-1 flex items-center justify-between'>
                <p className='font-medium text-red-700'>
                    To:
                </p>
                <p className='text-sm font-light w-full text-nowrap overflow-hidden text-right'>
                    {dropOff}
                </p>
            </div>
            <div className='w-full mb-1 flex items-center justify-between'>
                <p className='font-medium '>
                    Fare:
                </p>
                <p className='text-sm font-light'>
                    {amount}
                </p>
            </div>
            <div className='w-full mb-1 flex items-center justify-between'>
                <p className='font-medium '>
                    Ride Distance:
                </p>
                <p className='text-sm font-light'>
                    {Math.round(distance)}Km
                </p>
            </div>
            {parcel.parcel_image &&
            <div className='w-full flex items-center justify-end'>
                <button
                    onClick={() => setViewParcel(true)}
                    className='underline text-sm text-orange-600'
                >
                    View Photo
                </button>
            </div>}
            <div className='flex items-center justify-between mt-3 px-4'>
                <button 
                    onClick={handleCancelOrder} 
                    className='text-red-600 font-semibold flex flex-col items-center justify-center cursor-pointer'
                >
                    <p className='card-option-icon'>
                        <RxCross1 />
                    </p>
                    <p className='card-option-text'>
                        cancel
                    </p>
                </button>
                <button 
                    onClick={() => setShowOfferWindow(prev=> !prev)}
                    className='flex flex-col items-center justify-center cursor-pointer'
                >
                    <p className='card-option-icon'>
                        <IoMdChatbubbles />
                    </p>
                    <p className='card-option-text'>
                        Offer
                    </p>
                </button>
                <button 
                    onClick={handleAcceptOrder}
                    className='text-green-600 font-semibold flex flex-col items-center justify-center cursor-pointer'
                >
                    <p className='card-option-icon'>
                        <IoCheckmark />
                    </p>
                    <p className='card-option-text'>
                        Accept
                    </p>
                </button>
            </div>
            {/* <GradientBtn onClickHandler={} text={btnText} /> */}
    </div>
  )
}

export default NewOrderCard