import React, { useState, useRef, useCallback, useEffect } from 'react';
import Webcam from 'react-webcam';

const Camera = ({ setPhotos, setIsCameraVisible }) => {
  const webcamRef = useRef(null);
  const [cameraPermission, setCameraPermission] = useState(null);
  const [loading, setLoading] = useState(true);
  const [mediaStream, setMediaStream] = useState(null);

  const stopCamera = () => {
    if (mediaStream) {
      const tracks = mediaStream.getTracks();
      tracks.forEach(track => track.stop());
    }
  };
  
  const handleClose = () => {
    stopCamera(); // Stop the camera when the component is closed
    setIsCameraVisible(false);
  };

  const requestCameraPermission = useCallback(async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: 'user' } });
      setMediaStream(stream);
      setCameraPermission(true);
      setLoading(false);
    } catch (error) {
      setCameraPermission(false);
      console.error('Error accessing camera:', error);
    }
  }, []);

  useEffect(() => {
    requestCameraPermission();
  }, [requestCameraPermission]);

  const capturePhoto = () => {
    if (cameraPermission) {
      const photo = webcamRef.current.getScreenshot();
      const canvas = document.createElement('canvas');
      const img = document.createElement('img');
      img.src = photo;
      img.onload = () => {
        const size = Math.min(img.width, img.height);
        canvas.width = size;
        canvas.height = size;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(
          img,
          (img.width - size) / 2,
          (img.height - size) / 2,
          size,
          size,
          0,
          0,
          size,
          size
        );
  
        canvas.toBlob(blob => {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(blob);
          fileReader.onloadend = () => {
            const base64data = fileReader.result;
            setPhotos({
              image: base64data,
              blob: blob
            });
            stopCamera(); // Stop the camera after capturing the photo
            setIsCameraVisible(false);
          };
        }, 'image/jpeg', 0.7);
      };
    } else {
      console.error('Camera permission not granted.');
    }
  };
  
  
// const capturePhoto = () => {
//     if (cameraPermission) {
//       const photo = webcamRef.current.getScreenshot();
//       const canvas = document.createElement('canvas');
//       const img = document.createElement('img');
//       img.src = photo;
//       img.onload = () => {
//         // Determine the smallest side
//         const size = Math.min(img.width, img.height);
  
//         // Adjust canvas dimensions to square
//         canvas.width = size;
//         canvas.height = size;
  
//         const ctx = canvas.getContext('2d');
  
//         // Draw the image centered on the square canvas
//         ctx.drawImage(
//           img,
//           (img.width - size) / 2,  // Start x
//           (img.height - size) / 2, // Start y
//           size, // Width to draw
//           size, // Height to draw
//           0,    // Destination x
//           0,    // Destination y
//           size, // Destination width
//           size  // Destination height
//         );
  
//         // Save the image as a Blob and set it in the state
//         canvas.toBlob(blob => {
//           setPhotos(blob); // Save the Blob directly
//           setIsCameraVisible(false);
//         }, 'image/jpeg', 0.7); // Adjust quality as needed
//       };
//     } else {
//       console.error('Camera permission not granted.');
//     }
//   };

  return (
    <div className="w-[40rem] rounded-2xl p-5 bg-white backdrop-blur-xl relative">
      <button
        className="absolute top-5 left-5 text-2xl text-black"
        onClick={handleClose}
      >
        X
      </button>

      {cameraPermission &&
        <>
          <div className='w-full aspect-square'>
            <Webcam
              className="w-full h-full"
              ref={webcamRef}
              onUserMedia={requestCameraPermission}
            />
          </div>
          <button
            className="w-20 aspect-square rounded-full border-2 border-black p-1"
            onClick={capturePhoto}
          >
            <div className='w-full h-full rounded-full bg-black'></div>
          </button>
        </>}

      {loading &&
        <p className='error-message'>
          Permission Denied or camera not found
        </p>}

    </div>
  );
};

export default Camera;