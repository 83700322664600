import React, { useEffect, useState } from 'react'
import compassIcon from "../../assets/icons/compassIcon.png"
import compassActiveIcon from "../../assets/icons/compassActive.png"
import healthIcon from "../../assets/icons/healthIcon.png"
import healthActiveIcon from "../../assets/icons/healthActive.png"
import settingsIcon from "../../assets/icons/settingsIcon.png"
import settingsActiveIcon from "../../assets/icons/settingsActive.png"
import { useNavigate } from 'react-router-dom'

const noNavRoutes = [
    "/login",
    "/signup",
    "/signup/car"
]

const NavigationBar = () => {
    const navigate = useNavigate()
    const pathname = window.location.pathname
    const [ activePath, setActivePath ] = useState(0)

    useEffect(() => {
        if (pathname === "/driver") {
            setActivePath(0)
        } else if (pathname === "/earnings") {
            setActivePath(1)
        } else if (pathname === "/trip-history") {
            setActivePath(2)
        }
    }, [pathname])

    const handleClick = (url, id) => {
        setActivePath(id)
        navigate(url)
    }

    return (
    <div className={`${noNavRoutes.includes(pathname)? "hidden" : ""} w-full fixed bottom-0 left-0 bg-white rounded-t-xl shadow-2xl h-20 z-[90] border-2 p-4 flex items-center justify-between md:px-4 md:-translate-x-1/2 md:left-1/2 md:w-[95%] md:rounded-full md:bottom-2`}>
        <div 
            onClick={() => handleClick("/driver", 0)}
            className='h-full aspect-square'
        >
            <img 
                src={(activePath === 0)? compassActiveIcon : compassIcon} 
                className='w-full h-full' 
                alt='map' 
            />
        </div>

        <div 
            onClick={() => handleClick("/earnings", 1)}
            className='h-full aspect-square'>
            <img 
                src={(activePath === 1)? healthActiveIcon : healthIcon} 
                className='w-full h-full' 
                alt='health' 
            />
        </div>

        <div 
            className='h-full aspect-square'
            onClick={() => handleClick("/trip-history", 2)}
        >
            <img 
                src={(activePath === 2)? settingsActiveIcon : settingsIcon} 
                className='w-full h-full'
                alt='settings' 
            />
        </div>
    </div>
  )
}

export default NavigationBar